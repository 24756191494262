<template>
  <div class="wrapper device-log" ref="page">
    <ctheader></ctheader>
    <div class="content">
      <div class="table-head">
        <div class="left">
          <!-- <flatpickr :width="200" :config="flatpickrConfig" name="noPayTime" placeholder="上传数据时间" :getDateCallback="getDate"></flatpickr> -->
          <button class="btn primary ver-ali-top" @click="add">上传下发</button>
        </div>
      </div>
      <div class="table-scroll">
        <table class="table" width="100%">
          <thead>
            <tr>
              <th v-for="field in fields" :class="field.class">
                {{ field.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in dataList">
              <td class="pw30">{{ row.log_name }}</td>
              <td class="pw30">{{ row.updated_at | filterSecond }}</td>
              <td class="pw20">
                <span v-if="row.status === 0">终止</span>
                <span v-if="row.status === 1">待上传</span>
                <span v-if="row.status === 2">已完成</span>
              </td>
              <td class="operation pw20">
                <a v-if="row.status === 2" @click="load(row)">下载</a>
                <!-- <a v-if="row.upload_status === 2" @click="upload(row)">重新上传</a> -->
                <a v-if="row.status === 1" @click="stop(row)">
                  终止
                </a>
              </td>
            </tr>
            <tr v-if="dataList.length === 0">
              <td class="empty" :colspan="fields.length">没有相关记录</td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        :start="tableSet.start"
        :total="tableSet.total"
        :page-length="tableSet.length"
        :pageCallback="getList"
      ></pagination>
      <layers
        :show="isShow"
        title="上传下发"
        :width="580"
        :btnLoadDisabled="disabled"
        :saveCallBack="saveLog"
        :closeCallBack="cancel"
      >
        <div class="form">
          <div class="field">
            <span
              style="margin-right:8px;width:80px;display: inline-block;text-align:right"
              >下发时间</span
            >
            <!-- <flatpickr
              :width="300"
              :config="flatpickrConfig"
              name="noPayTime"
              placeholder="上传数据时间"
              :getDateCallback="getDate"
            ></flatpickr> -->
            <el-date-picker
              v-model="value2"
              type="datetimerange"
              align="right"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="small"
              value-format="timestamp"
              :picker-options="siginsurepickerOptions"
              :default-time="['00:00:00', '23:59:59']"
            >
              >
            </el-date-picker>
          </div>
          <div class="field">
            <!-- <label class="w120 tar">类型</label> -->
            <span
              style="margin-right:8px;width:80px;display: inline-block;text-align:right"
            >
              类型
            </span>
            <select class="w300 mgl-8 ver-ali-top" v-model="tableSet.type">
              <option :value="row.id" v-for="row in typeList">{{
                row.name
              }}</option>
            </select>
          </div>
        </div>
      </layers>
    </div>
  </div>
</template>

<script>
/**
 * @file pages/index.vue
 * @desc 下载日志
 * @version 0.1.0
 * @author songkexin <songkexin@rongyi.com>
 * @since 2021-04-09
 * @copyright 2021
 */
/* jshint esversion: 6 */
import { mapGetters, mapActions } from "vuex";
import APIs from "../../modules/APIs";
import { apiPost, getDate } from "../../modules/utils";
// import ctheader from '@@/ctheader';
// import flatpickr from '@@/flatpickr';
// import pagination from '@@/pagination';
// import layers from '@@/layers';
import flatpickr from "../../components/flatpickr";
import ctheader from "../../components/ctheader";
import pagination from "../../components/pagination";
import layers from "../../components/layers";
import moment from "moment";

const getters = mapGetters([]);

const actions = mapActions(["setAlert"]);

const fields = [
  { name: "文件名称", class: "pw30" },
  { name: "上传时间", class: "pw30" },
  { name: "状态", class: "pw20" },
  { name: "操作", class: "pw20" },
];

/**
 * @module index.vue
 * @desc this is module index.vue
 * @author songkexin <songkexin@rongyi.com>
 * @since 2021-04-09
 * @copyright 2021
 */
export default {
  data() {
    return {
      fields,
      dataList: [],
      pageInfo: {},
      tableSet: {
        start: 0,
        length: 10,
        total: 0,
        keyword: "",
        hq_code: "",
        marketId: "",
        startDate: "",
        endDate: "",
        type: 0,
      },
      flatpickrConfig: {
        minDate: null,
        maxDate: getDate(),
      },
      isShow: false,
      disabled: false,
      typeList: [
        { id: 0, name: "全部类型" },
        { id: 1, name: "日志" },
        { id: 2, name: "数据库" },
      ],
      value2: [],

      timeOptionRange: null,
      siginsurepickerOptions: {
        //只能选择今天 和未来七天的时间
        disabledDate: (time) => {
          let timeOptionRange = new Date(
            new Date(new Date().toLocaleDateString()).getTime() +
              24 * 60 * 60 * 1000 -
              1
          );
          // 设置7的时间戳
          let secondNum = 7 * 24 * 60 * 60 * 1000;
          // 获取选中时间
          // let timeOptionRange = this.timeOptionRange;
          if (timeOptionRange) {
            return (
              time.getTime() > timeOptionRange.getTime() ||
              time.getTime() <= timeOptionRange.getTime() - secondNum
            );
          }
        },
      },
    };
  },
  components: { ctheader, flatpickr, pagination, layers },
  computed: {
    ...getters,
  },
  filters: {
    filterSecond(num) {
      return moment.unix(num).format("YYYY-MM-DD HH:mm:ss");
    },
  },

  methods: {
    add() {
      this.isShow = true;
    },
    cancel() {
      this.isShow = false;
    },
    getDate(dates) {
      this.tableSet.startDate = dates.start;
      this.tableSet.endDate = dates.end;
      this.getList();
    },
    /**
     * pos下发
     * @Author   songkexin     <songkexin@rongyi.com>
     * @DateTime 2021-07-27
     * @return   {[type]}   [description]
     */
    saveLog() {
      let $this = this;
      console.log(this.value2);
      this.tableSet.begin_date = this.value2[0] / 1000;
      this.tableSet.end_date = this.value2[1] / 1000;
      apiPost({
        url: APIs.POS,
        data: {
          action: "log.add",
          // hq_code: this.tableSet.hq_code,
          pos_id: this.tableSet.marketId,
          end_date: this.tableSet.end_date,
          begin_date: this.tableSet.begin_date,
          log_type: this.tableSet.type,
        },
        success(res) {
          $this.setAlert({ msg: res.msg, type: res.code === "0" });
          $this.getList();
          $this.cancel();
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    /**
     * 下载包
     * @Author   songkexin     <songkexin@rongyi.com>
     * @DateTime 2021-07-27
     * @param    {[type]}   row                       [description]
     */
    load(row) {
      // window.open(row.log_url);
      if (row.log_url) {
        setTimeout(() => {
          // window.open(row.log_url);
          window.location.href= row.log_url 
        }, 100);
      }
      if (row.sql_url) {
        setTimeout(() => {
          // window.open(row.sql_url);
          window.location.href = row.sql_url

        }, 300);
      }
    },
    /**
     * 上传包
     * @Author   songkexin     <songkexin@rongyi.com>
     * @DateTime 2021-07-27
     * @param    {[type]}   row                       [description]
     */
    upload(row) {
      let $this = this;
      apiPost({
        url: APIs.POS,
        data: {
          action: "again.pos.log",
          hq_code: this.tableSet.hq_code,
          id: row.id,
        },
        success(res) {
          $this.setAlert({ msg: res.msg, type: res.code === "0" });
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    /**
     * 强行停止
     * @Author   songkexin     <songkexin@rongyi.com>
     * @DateTime 2021-08-05
     * @param    {[type]}   row                       [description]
     */
    stop(row) {
      let $this = this;
      apiPost({
        url: APIs.POS,
        data: {
          action: "log.update",
          id: row.id,
        },
        success(res) {
          if (res.code === "0") {
            $this.getList();
          }
          $this.setAlert({ msg: res.msg, type: res.code === "0" });
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    getList(index = 0) {
      let $this = this;
      this.tableSet.start = index;
      apiPost({
        url: APIs.POS,
        data: {
          action: "log.list",
          // hq_code: this.tableSet.hq_code,
          pos_id: this.tableSet.marketId,
          start: this.tableSet.start,
          length: this.tableSet.length,
        },
        success(res) {
          if (res.code === "0") {
            $this.dataList = res.data;
            $this.tableSet.total = res.total;
          } else {
            $this.dataList = [];
            $this.tableSet.total = 0;
          }
        },
        error(res) {
          $this.dataList = [];
          $this.tableSet.total = 0;
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    ...actions,
  },
  mounted() {
    this.tableSet.hq_code = this.$route.query.hq_code || "";
    this.tableSet.marketId = this.$route.query.id || "";
    this.getList();
  },
};
</script>

<style lang="less">
@import url("../../less/common.less");
.device-log {
  .form {
    padding-left: 20px;
    .pdt(40px);
    label {
      width: 120px;
      .ta-rt();
    }
  }
}
.el-button--text {
  color: #257873;
}
.el-date-table td.end-date span,
.el-date-table td.start-date span {
  background: #257873;
}
.el-date-table td.today span {
  color: #257873;
}
</style>
